import { createSlice } from '@reduxjs/toolkit';
import { TCountryMfn } from '../../../types/module';

interface CountryMfnState {
  countryMfns: TCountryMfn[];
  countryMfn: TCountryMfn;
}

const initialState: CountryMfnState = {
  countryMfns: [],
  countryMfn: <TCountryMfn>{}
};

const countryMfnSlice = createSlice({
  name: 'countryMfn',
  initialState,
  reducers: {
    getAllCountryMfn(state, action) {
      state.countryMfns = action.payload.results || [];
    },
    getOneCountryMfn(state, action) {
      state.countryMfn = action.payload.row || null;
    },
    createOneCountryMfn(state, action) {
      state.countryMfns = [...state.countryMfns, action.payload.row];
    },
    updateOneCountryMfn(state, action) {
      state.countryMfns = state.countryMfns.map(item => {
        if (item.countryMFNId !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },

    removeOneCountryMfn(state, action) {
      state.countryMfns = state.countryMfns.filter(
        item => item.countryMFNId !== action.payload.id
      );
    }
  }
});

export const {
  getAllCountryMfn,
  getOneCountryMfn,
  createOneCountryMfn,
  updateOneCountryMfn,
  removeOneCountryMfn
} = countryMfnSlice.actions;
export default countryMfnSlice.reducer;
