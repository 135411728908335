import { useDispatch, useSelector } from '../../store';

import CmsContentService from '../../services/CmsContentService';
import MediaService from '../../services/MediaService';
import {
  createOneCmsContent,
  getAllCmsContent,
  getOneCmsContent,
  removeOneCmsContent,
  updateOneCmsContent
} from '../../store/reducers/modules/cmsContentSlice';
import { TCmsContent } from '../../types/cms-content';

const useCmsContentHook = () => {
  const dispatch = useDispatch();
  const { cmsContents, cmsContent } = useSelector(state => state.cmsContent);

  const fetchAllCmsContent = async (
    language: number
  ): Promise<TCmsContent[]> => {
    try {
      const resp = await CmsContentService.fetchAll(language);
      let results = resp?.data || [];
      const preDefineSlugs = [
        'home',
        'agreement',
        'first-time-exporter',
        'preferential-treatment'
      ];
      results = results.filter(
        (data: TCmsContent) => !preDefineSlugs.includes(data.slug as string)
      );

      dispatch(getAllCmsContent({ results }));
      return results;
    } catch (e) {
      console.log('fetchAllCmsContent', e);
      throw e;
    }
  };
  const fetchOneCmsContent = async (slug: string, languageId: number) => {
    try {
      const resp = await CmsContentService.fetchOne(slug, languageId);
      let row = resp?.data || null;
      row = await MediaService.mappedMediaUrl(row);

      dispatch(getOneCmsContent({ row }));
      return row;
    } catch (e) {
      dispatch(getOneCmsContent({ row: null }));
      console.log('fetchOneCmsContent', e);
      throw e;
    }
  };
  const createCmsContent = async (data: TCmsContent): Promise<TCmsContent> => {
    try {
      const resp = await CmsContentService.create(data);
      let row = resp?.data || null;
      row = await MediaService.mappedMediaUrl(row);
      dispatch(createOneCmsContent({ row }));
      return row;
    } catch (e) {
      console.log('createCmsContent', e);
      throw e;
    }
  };
  const updateCmsContent = async (id: number, data: TCmsContent) => {
    try {
      await CmsContentService.update(id, data);
      const row = await MediaService.mappedMediaUrl(data);
      dispatch(updateOneCmsContent({ id, row }));
    } catch (e) {
      console.log('updateCmsContent', e);
      throw e;
    }
  };
  const deleteCmsContent = async (id: number) => {
    try {
      await CmsContentService.destroy(id);
      dispatch(removeOneCmsContent({ id }));
    } catch (e) {
      console.log('deleteCmsContent', e);
      throw e;
    }
  };
  return {
    fetchAllCmsContent,
    fetchOneCmsContent,
    createCmsContent,
    updateCmsContent,
    deleteCmsContent,
    removeOneCmsContent,
    cmsContents,
    cmsContent
  };
};

export default useCmsContentHook;
