// import { BadgeBg } from 'components/base/Badge';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const memberBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Pages',
    url: '#!'
  },
  {
    label: 'Members',
    active: true
  }
];

export type Member = {
  contentType: string;
  slug: string;
  description: string;
  createdOn: string;
};

export const members: Member[] = [
  {
    contentType: 'Keynotes',
    slug: 'keynotes',
    description:
      'Maecenas ut turpis eget neque venenatis eugiat condimentum nibh tristique. Sed vel nibh laoreet, iaculis nulla sit amet',
    createdOn: 'Dec 12, 12:56 PM'
  }
];
