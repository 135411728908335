import axios from 'axios';
import { getAuthFromLocalStorage } from './storage';

const auth = getAuthFromLocalStorage();

const axiosServices = axios.create({
  baseURL: `${process.env.REACT_APP_ENDPOINT}`,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
    Authorization: 'Bearer ' + auth.token //the token is a variable which holds the token
  }
});

axiosServices.interceptors.response.use(
  async response => {
    return response;
  },
  async error => {
    console.log(error);
    if (error.response?.status === 401) {
      window.location.href = window.location.origin + '/auth/sign-in';
    }
    return Promise.reject(error.response || 'Something went wrong');
  }
);

export default axiosServices;
