import { createSlice } from '@reduxjs/toolkit';
import { TPsrType } from '../../../types/module';

interface PsrTypeState {
  psrTypes: TPsrType[];
  psrType: TPsrType;
}

const initialState: PsrTypeState = {
  psrTypes: [],
  psrType: <TPsrType>{}
};

const psrTypeSlice = createSlice({
  name: 'psrType',
  initialState,
  reducers: {
    getAllPsrType(state, action) {
      state.psrTypes = action.payload.results || [];
    },
    getOnePsrType(state, action) {
      state.psrType = action.payload.row || null;
    },
    createOnePsrType(state, action) {
      state.psrTypes = [...state.psrTypes, action.payload.row];
    },
    updateOnePsrType(state, action) {
      state.psrTypes = state.psrTypes.map(item => {
        if (item.psrId !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },

    removeOnePsrType(state, action) {
      state.psrTypes = state.psrTypes.filter(
        item => item.psrId !== action.payload.id
      );
    }
  }
});

export const {
  getAllPsrType,
  getOnePsrType,
  createOnePsrType,
  updateOnePsrType,
  removeOnePsrType
} = psrTypeSlice.actions;
export default psrTypeSlice.reducer;
