import { createSlice } from '@reduxjs/toolkit';
import { TFtaCountry } from '../../../types/fta';

interface FtaCountryState {
  ftaCountries: TFtaCountry[];
  ftaCountry: TFtaCountry;
}

const initialState: FtaCountryState = {
  ftaCountries: [],
  ftaCountry: <TFtaCountry>{}
};

const ftaCountrySlice = createSlice({
  name: 'ftaCountry',
  initialState,
  reducers: {
    getAllFtaCountry(state, action) {
      state.ftaCountries = action.payload.results || [];
    },
    getOneFtaCountry(state, action) {
      state.ftaCountry = action.payload.row || null;
    },
    createOneFtaCountry(state, action) {
      state.ftaCountries = [...state.ftaCountries, action.payload.row];
    },
    updateOneFtaCountry(state, action) {
      state.ftaCountries = state.ftaCountries.map(item => {
        if (item.ftaCountryId !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },

    removeOneFtaCountry(state, action) {
      state.ftaCountries = state.ftaCountries.filter(
        item => item.ftaCountryId !== action.payload.id
      );
    }
  }
});

export const {
  getAllFtaCountry,
  getOneFtaCountry,
  createOneFtaCountry,
  updateOneFtaCountry,
  removeOneFtaCountry
} = ftaCountrySlice.actions;
export default ftaCountrySlice.reducer;
