import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCog,
  faCopy,
  faFileExport,
  faGlobe,
  faHomeAlt,
  faPlane,
  faPlusCircle,
  faSign,
  faStethoscope,
  faUser,
  faUsers,
  faBuilding
} from '@fortawesome/free-solid-svg-icons';
import { Icon, UilFilesLandscapesAlt } from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
  next?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'Free Trade Agreement',
    icon: UilFilesLandscapesAlt,
    active: true,
    pages: [
      {
        name: 'FTA List',
        iconSet: 'font-awesome',
        icon: faPlane,
        path: '/fta',
        pathName: 'fta-list',
        active: true
      },

      {
        name: 'Add New FTA',
        iconSet: 'font-awesome',
        icon: faPlusCircle,
        path: '/fta/add',
        pathName: 'add',
        active: true
      },
      {
        name: 'Country',
        iconSet: 'font-awesome',
        icon: faGlobe,
        path: '/country',
        pathName: 'country',
        active: true
      }
    ]
  },

  {
    label: 'Content Management System',
    icon: UilFilesLandscapesAlt,
    active: true,
    pages: [
      {
        name: 'Pages',
        iconSet: 'font-awesome',
        icon: faCopy,
        path: '/pages',
        pathName: 'pages',
        active: true
      },
      {
        name: 'Home',
        iconSet: 'font-awesome',
        icon: faHomeAlt,
        path: '/home',
        pathName: 'home',
        active: true
      },
      {
        name: 'Agreements',
        iconSet: 'font-awesome',
        icon: faSign,
        path: '/agreements',
        pathName: 'agreements',
        active: true
      },
      {
        name: 'First Time Exporter',
        iconSet: 'font-awesome',
        icon: faFileExport,
        path: '/first-time-exporter',
        pathName: 'first-time-exporter',
        active: true
      },
      {
        name: 'Preferential Treatment',
        iconSet: 'font-awesome',
        icon: faStethoscope,
        path: '/preferential-treatment',
        pathName: 'preferential-treatment',
        active: true
      }
    ]
  },
  {
    label: 'User Management',
    icon: UilFilesLandscapesAlt,
    active: true,
    pages: [
      {
        name: 'User List',
        iconSet: 'font-awesome',
        icon: faUsers,
        path: '/user',
        pathName: 'user',
        active: true
      },
      {
        name: 'Company',
        iconSet: 'font-awesome',
        icon: faBuilding,
        path: '/company',
        pathName: 'company',
        active: true
      }
    ]
  },
  {
    label: 'Settings',
    icon: UilFilesLandscapesAlt,
    active: true,
    pages: [
      {
        name: 'General Settings',
        iconSet: 'font-awesome',
        icon: faCog,
        path: '/settings',
        pathName: 'settings',
        active: true
      },
      {
        name: 'profile',
        iconSet: 'font-awesome',
        icon: faUser,
        path: '/profile',
        pathName: 'profile',
        active: true
      }
    ]
  }
];
