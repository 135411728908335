import { faFile, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Accordion, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import usa from '../../../assets/img/country/usa.png';
import Button from 'components/base/Button';

const ContentTypeAdd = () => {
  return (
    <>
      <div className="mx-n4 px-4 py-3 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent top-section">
        <div></div>
        <div className="d-flex align-items-center">
          <div className="top-section__lang d-flex align-items-center">
            <img src={usa} alt="" />
            <Form.Select aria-label="Default Language">
              <option value="1">English</option>
              <option value="2">ខ្មែរ</option>
            </Form.Select>
          </div>
          <div className="d-flex gap-2">
            <Button variant="phoenix-secondary">
              <FontAwesomeIcon icon={faFile} className="me-2" />
              Draft
            </Button>
            <Button variant="primary">
              <FontAwesomeIcon icon={faSave} className="me-2" />
              Publish Now
            </Button>
          </div>
        </div>
      </div>
      <div className="c-content gap-5">
        <div className="c-content__left mt-5">
          <h2 className="mb-4">Add Content Type</h2>
          <Row>
            <Col md={6} className="mb-5">
              <FloatingLabel controlId="floatingInput" label="Title">
                <Form.Control type="text" placeholder="Title" />
              </FloatingLabel>
            </Col>
            <Col md={6} className="mb-5">
              <FloatingLabel controlId="floatingInput" label="Slug">
                <Form.Control type="text" placeholder="Slug" />
              </FloatingLabel>
            </Col>
            <Col md={12} className="mb-5">
              Dropzone Plugins
            </Col>
          </Row>
        </div>
        <div className="c-content__right bg-body-emphasis border-translucent">
          <div className="d-flex flex-column gap-3">
            <Accordion defaultActiveKey={['0', '1', '2']} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Menu Order</Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex flex-column gap-3">
                    <FloatingLabel controlId="floatingSelect" label="Icon">
                      <Form.Select aria-label="Floating label select example">
                        <option>Select</option>
                        <option value="fb">facebook</option>
                      </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel controlId="floatingInput" label="Menu Order">
                      <Form.Control type="text" placeholder="Menu Order" />
                    </FloatingLabel>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Description</Accordion.Header>
                <Accordion.Body>
                  <Form.Control as="textarea" rows={4} />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Custom Fields</Accordion.Header>
                <Accordion.Body>
                  <p className="fs-9">Drag and drop the required fields</p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentTypeAdd;
