import axios from 'axios';
import {
  TAuthUser,
  TChangePassword,
  TForgotPassword,
  TLogin,
  TResetPassword
} from '../types/auth';
import { getAuthFromLocalStorage } from '../utils/storage';
import api from '../utils/api';

const authEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/Auth`;

const login = (data: TLogin) => {
  return axios.post(`${authEndpoint}/login`, data);
};
const forgotPassword = (data: TForgotPassword) => {
  return axios.post(`${authEndpoint}/request-password-reset`, data);
};
const resetPassword = (data: TResetPassword) => {
  return axios.post(`${authEndpoint}/password-reset`, data);
};
const changePassword = (data: TChangePassword) => {
  return api.post(`${authEndpoint}/change-password`, data);
};
const updateProfile = (data: TAuthUser) => {
  return api.put(`${authEndpoint}/update-profile`, data);
};

const logout = () => {
  const auth = getAuthFromLocalStorage();
  return axios.post(
    `${authEndpoint}/logout`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + auth.token //the token is a variable which holds the token
      }
    }
  );
};

const AuthService = {
  login,
  logout,
  forgotPassword,
  resetPassword,
  updateProfile,
  changePassword
};
export default AuthService;
