import { useEffect } from 'react';
import useLanguageHook from '../../hooks/modules/useLanguageHook';
import NotFoundImage from '../../assets/img/not-found.png';
import { Form } from 'react-bootstrap';
import { TLanguage } from '../../types/module';
import { useNavigate } from 'react-router-dom';

type Props = {
  isKhmerDisabled?: boolean;
  isFTADetail?: boolean;
  isFTAEdit?: boolean;
  isFTARoo?: boolean;
  englishFTAId?: number | null;
  khmerFTAId?: number | null;
  currentLanguage?: number | null;
};

const LanguageContainer = ({
  isKhmerDisabled,
  isFTADetail,
  isFTAEdit,
  isFTARoo,
  englishFTAId,
  khmerFTAId,
  currentLanguage
}: Props) => {
  const navigate = useNavigate();
  const { fetchAllLanguage, languages, setDefaultLanguage, defaultLanguage } =
    useLanguageHook();

  useEffect(() => {
    if (languages.length <= 0) {
      fetchAllLanguage().catch(e => console.log(e));
    }
  }, []);

  useEffect(() => {
    if (!defaultLanguage && languages.length > 0) {
      setDefaultLanguage(languages[0]).catch(e => console.log(e));
    }
  }, [languages]);

  useEffect(() => {
    const foundLanguage = languages?.find(
      item => item.languageId === Number(currentLanguage)
    );

    if (foundLanguage) {
      setDefaultLanguage(foundLanguage);
    }
  }, [currentLanguage, languages]);

  // eslint-disable-next-line
  const handleOnSelected = (e: any) => {
    const code = e?.target?.value;

    if (isFTADetail) {
      const ftaId =
        code === 'km' ? khmerFTAId : code === 'en' ? englishFTAId : null;
      if (ftaId) {
        navigate(`/fta/detail/${ftaId}`);
      }
    }

    if (isFTAEdit) {
      const ftaId =
        code === 'km' ? khmerFTAId : code === 'en' ? englishFTAId : null;
      if (ftaId) {
        navigate(`/fta/add/${ftaId}`);
      }
    }

    if (isFTARoo) {
      const ftaId =
        code === 'km' ? khmerFTAId : code === 'en' ? englishFTAId : null;
      if (ftaId) {
        navigate(`/fta/ROO/upload/${ftaId}`);
      }
    }

    const filter = languages.filter(data => data.code == code);
    if (filter.length > 0) {
      setDefaultLanguage(filter[0] as TLanguage).catch(e => console.log(e));
    }
  };

  return (
    <div className="mx-n4 px-4 py-1 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent top-section mb-5">
      <div></div>
      <div className="top-section__lang d-flex align-items-center">
        <img
          style={{ height: '18px' }}
          src={defaultLanguage?.mediaUrl || NotFoundImage}
          alt={defaultLanguage?.name}
        />
        <Form.Select
          aria-label="Default Language"
          value={defaultLanguage?.code as string}
          onChange={handleOnSelected}
        >
          {languages.map(data => (
            <option
              key={data?.code}
              value={data?.code as string}
              disabled={isKhmerDisabled && data?.code === 'km'}
            >
              {data?.name}
            </option>
          ))}
        </Form.Select>
      </div>
    </div>
  );
};
export default LanguageContainer;
