import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { Member } from 'data/members';

export const membersTablecolumns: ColumnDef<Member>[] = [
  {
    accessorKey: 'contentType',
    header: 'Name',
    cell: ({ row: { original } }) => {
      const { contentType } = original;
      return (
        <Link to="#!" className=" d-flex align-items-center text-body">
          <p className="mb-0 text-body-emphasis fw-semibold">{contentType}</p>
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '200px' }
      },
      cellProps: { className: 'align-middle white-space-nowrap py-2' }
    }
  },
  {
    accessorKey: 'slug',
    header: 'Slug',
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'text-body' }
    }
  },
  {
    accessorKey: 'description',
    header: 'Description',
    meta: {
      headerProps: { style: { width: '30%' } },
      cellProps: { className: 'text-body' }
    }
  },
  {
    accessorKey: 'createdOn',
    header: 'Created On',
    meta: {
      headerProps: { style: { width: '25%' } },
      cellProps: { className: 'text-body' }
    }
  }
];

const MembersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default MembersTable;
