import { useDispatch, useSelector } from '../../store';
import { TLanguage } from '../../types/module';
import LanguageService from '../../services/LanguageService';
import {
  createOneLanguage,
  getAllLanguage,
  getOneLanguage,
  removeOneLanguage,
  setOneDefaultLanguage,
  updateOneLanguage
} from '../../store/reducers/modules/languageSlice';
import MediaService from '../../services/MediaService';

const useLanguageHook = () => {
  const dispatch = useDispatch();
  const { languages, language, defaultLanguage } = useSelector(
    state => state.language
  );

  const fetchAllLanguage = async (): Promise<TLanguage[]> => {
    try {
      const resp = await LanguageService.fetchAll();
      const results = resp?.data || [];
      for (let i = 0; i < results.length; i++) {
        const row = await MediaService.mappedMediaUrl(results[i]);
        results[i].mediaUrl = row.mediaUrl;
      }
      dispatch(getAllLanguage({ results }));
      return results;
    } catch (e) {
      console.log('fetchAllLanguage', e);
      throw e;
    }
  };
  const fetchOneLanguage = async (data: TLanguage): Promise<TLanguage> => {
    try {
      const row = data || null;
      dispatch(getOneLanguage({ row }));
      return row;
    } catch (e) {
      console.log('fetchOneLanguage', e);
      throw e;
    }
  };

  const setDefaultLanguage = async (data: TLanguage) => {
    try {
      dispatch(setOneDefaultLanguage({ row: data }));
      return data;
    } catch (e) {
      console.log('setDefaultLanguage', e);
      throw e;
    }
  };
  const createLanguage = async (data: TLanguage): Promise<TLanguage> => {
    try {
      const mediaUrl = data?.mediaUrl;
      const resp = await LanguageService.create(data);
      const row = resp?.data || null;
      row.mediaUrl = mediaUrl;

      dispatch(createOneLanguage({ row }));
      return row;
    } catch (e) {
      console.log('createLanguage', e);
      throw e;
    }
  };
  const updateLanguage = async (id: number, data: TLanguage) => {
    try {
      await LanguageService.update(id, data);
      dispatch(updateOneLanguage({ id, row: data }));
    } catch (e) {
      console.log('updateLanguage', e);
      throw e;
    }
  };
  const deleteLanguage = async (id: number) => {
    try {
      await LanguageService.destroy(id);
      dispatch(removeOneLanguage({ id }));
    } catch (e) {
      console.log('deleteLanguage', e);
      throw e;
    }
  };
  return {
    fetchAllLanguage,
    fetchOneLanguage,
    createLanguage,
    updateLanguage,
    deleteLanguage,
    setDefaultLanguage,
    languages,
    defaultLanguage,
    language
  };
};

export default useLanguageHook;
