import { createSlice } from '@reduxjs/toolkit';
import { TSetting } from '../../../types/module';

interface SettingState {
  settings: TSetting[];
}

const initialState: SettingState = {
  settings: []
};

const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    getAllSetting(state, action) {
      state.settings = action.payload.results || [];
    },
    setAllSetting(state, action) {
      state.settings = action.payload.results || [];
    }
  }
});

export const { getAllSetting, setAllSetting } = settingSlice.actions;
export default settingSlice.reducer;
