/* eslint-disable @typescript-eslint/no-explicit-any */
import IndeterminateCheckbox from 'components/base/IndeterminateCheckbox';
import { PropsWithChildren } from 'react';
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  InitialTableState,
  useReactTable
} from '@tanstack/react-table';
import { Button } from 'react-bootstrap';
import {
  faEdit,
  faEye,
  faTrashCan,
  faUpload
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface UseAdvanceTableProps<T> {
  columns: ColumnDef<T>[];
  action?: boolean;
  data: T[];
  selection?: boolean;
  sortable?: boolean;
  pagination?: boolean;
  pageSize?: number;
  selectionColumnWidth?: number | string;
  initialState?: InitialTableState;
  onView?: (data: any) => void;
  onEdit?: (data: any) => void;
  onDelete?: (data: any) => void;
  onUploadMfn?: (data: any) => void;
}

const selectionColumn = {
  id: 'select',
  accessorKey: '',
  header: ({ table }: any) => (
    <IndeterminateCheckbox
      className="form-check fs-8 mb-0"
      {...{
        checked: table.getIsAllRowsSelected(),
        indeterminate: table.getIsSomeRowsSelected(),
        onChange: table.getToggleAllRowsSelectedHandler()
      }}
    />
  ),
  cell: ({ row }: any) => (
    <IndeterminateCheckbox
      className="form-check fs-8 mb-0"
      {...{
        checked: row.getIsSelected(),
        disabled: !row.getCanSelect(),
        indeterminate: row.getIsSomeSelected(),
        onChange: row.getToggleSelectedHandler()
      }}
    />
  ),
  meta: {
    headerProps: { style: { width: '30px' } }
  }
};

const useAdvanceTable = <T,>({
  columns,
  action = true,
  data,
  selection,
  sortable,
  pagination,
  pageSize,
  initialState,
  onUploadMfn,
  onView,
  onDelete,
  onEdit
}: PropsWithChildren<UseAdvanceTableProps<T>>) => {
  const state = {
    pagination: pagination
      ? { pageSize: pagination ? pageSize : data.length }
      : undefined,
    ...initialState
  };
  const actionColumn = {
    header: `Action`,
    id: 'action',
    cell: (original: any) => {
      const { row } = original;

      return (
        <>
          <span className="d-flex justify-content-end">
            {onView && (
              <Button
                title="View"
                variant="subtle-secondary"
                onClick={() => {
                  onView?.(row?.original || null);
                }}
                className="btn-circle ml-2"
              >
                <FontAwesomeIcon icon={faEye} />
              </Button>
            )}
            {onUploadMfn && (
              <Button
                variant="subtle-secondary"
                title="Upload MFN"
                className="btn-circle ml-2"
                style={{ marginLeft: '4px' }}
                onClick={() => {
                  onUploadMfn?.(row?.original || null);
                }}
              >
                <FontAwesomeIcon icon={faUpload} />
              </Button>
            )}
            {onDelete && (
              <Button
                variant="subtle-danger"
                title="Delete"
                className="btn-circle ml-2"
                style={{ marginLeft: '4px' }}
                onClick={() => {
                  onDelete?.(row?.original || null);
                }}
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </Button>
            )}

            {onEdit && (
              <Button
                title="Edit"
                variant="subtle-info"
                className="btn-circle"
                onClick={() => {
                  onEdit?.(row?.original || null);
                }}
                style={{ marginLeft: '4px' }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            )}
          </span>
        </>
      );
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  };

  const handleColumns = () => {
    if (selection && action) {
      return [selectionColumn, ...columns, actionColumn];
    }

    if (selection) {
      return [selectionColumn, ...columns];
    }

    if (action) {
      return [...columns, actionColumn];
    }

    return [...columns];
  };
  const table = useReactTable<T>({
    data,
    columns: handleColumns(),
    enableSorting: sortable,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: state
  });

  return table;
};

export default useAdvanceTable;
