import { createSlice } from '@reduxjs/toolkit';
import { TFtaPsr } from '../../../types/fta';

interface FtaPsrState {
  ftaPsrs: TFtaPsr[];
  ftaPsr: TFtaPsr;
}

const initialState: FtaPsrState = {
  ftaPsrs: [],
  ftaPsr: <TFtaPsr>{}
};

const ftaPsrSlice = createSlice({
  name: 'ftaPsr',
  initialState,
  reducers: {
    getAllFtaPsr(state, action) {
      state.ftaPsrs = action.payload.results || [];
    },
    getOneFtaPsr(state, action) {
      state.ftaPsr = action.payload.row || null;
    },
    createOneFtaPsr(state, action) {
      state.ftaPsrs = [...state.ftaPsrs, action.payload.row];
    },
    updateOneFtaPsr(state, action) {
      state.ftaPsrs = state.ftaPsrs.map(item => {
        if (item.ftapsrId !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },

    removeOneFtaPsr(state, action) {
      state.ftaPsrs = state.ftaPsrs.filter(
        item => item.ftapsrId !== action.payload.id
      );
    }
  }
});

export const {
  getAllFtaPsr,
  getOneFtaPsr,
  createOneFtaPsr,
  updateOneFtaPsr,
  removeOneFtaPsr
} = ftaPsrSlice.actions;
export default ftaPsrSlice.reducer;
