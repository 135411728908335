import * as Yup from 'yup';

export const CountrySchema = Yup.object().shape({
  name: Yup.string().required('The name field is required.'),
  code: Yup.string()
    .min(2, 'The code must be at least 2 characters long.')
    .max(5, 'The code must be at most 5 characters long.')
});
export const CompanySchema = Yup.object().shape({
  name: Yup.string().required('The name field is required.')
});
export const LanguageSchema = Yup.object().shape({
  name: Yup.string().required('The name field is required.'),
  code: Yup.string()
    .required('The code field is required.')
    .max(2, 'The name must be length of 2.')
});
export const FtaTypeSchema = Yup.object().shape({
  name: Yup.string().required('The name field is required.')
});
export const PsrTypeSchema = Yup.object().shape({
  name: Yup.string().required('The name field is required.'),
  abbreviation: Yup.string().required('The abbreviation field is required.')
});
export const CmsContentSchema = Yup.object().shape({
  title: Yup.string().required('The title field is required.'),
  slug: Yup.string().required('The slug field is required.')
  // detail: Yup.object({
  //   accordions: Yup.array().of(
  //     Yup.object({
  //       title: Yup.string().required('The title field is required')
  //     })
  //   )
  // })
});
export const FtaSchema = Yup.object().shape({
  name: Yup.string().required('The title field is required.'),
  abbreviation: Yup.string().required('The abbreviation field is required.')
});
export const FtaCountrySchema = Yup.object().shape({
  ftaId: Yup.number().required('The ftaId field is required.'),
  countryId: Yup.string().required('The countryId field is required.')
});
export const GeneralSettingSchema = Yup.object().shape({
  companyName: Yup.string().required('The companyName field is required.')
});
export const CountryMfnSchema = Yup.object().shape({
  countryId: Yup.string().required('The countryId field is required.'),
  mfnVersion: Yup.string().required('The mfnVersion field is required.'),
  mfnFile: Yup.mixed().required('The mfnFile field is required.')
});
export const FtaPsrSchema = Yup.object().shape({
  ftaId: Yup.number().required('The ftaId field is required.'),

  psrVersion: Yup.string().required('The psrVersion field is required.'),
  psrFile: Yup.mixed().required('The psrFile field is required.')
});
export const FtaTrsSchema = Yup.object().shape({
  ftaId: Yup.number().required('The ftaId field is required.'),
  ftaCountryId: Yup.number().required('The ftaCountryId field is required.'),

  trsVersion: Yup.string().required('The trsVersion field is required.'),
  trsFile: Yup.mixed().required('The trsFile field is required.')
});

export const RooSchema = Yup.object().shape({
  questionText: Yup.string().required('The question field is required.')
});
