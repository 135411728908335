import { createSlice } from '@reduxjs/toolkit';
import { TLanguage } from '../../../types/module';

interface LanguageState {
  languages: TLanguage[];
  language: TLanguage;
  parentLanguage: TLanguage;
  defaultLanguage: TLanguage;
}

const initialState: LanguageState = {
  languages: [],
  language: <TLanguage>{},
  parentLanguage: <TLanguage>{},
  defaultLanguage: <TLanguage>{}
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    getAllLanguage(state, action) {
      state.languages = action.payload.results || [];
      const filter = state.languages.filter(
        data => data.code && data.code.toLowerCase() == 'en'
      );
      state.parentLanguage = filter?.[0] || state.languages?.[0];
      state.defaultLanguage = filter?.[0] || state.languages?.[0];
    },
    getOneLanguage(state, action) {
      state.language = action.payload.row || null;
    },
    setOneDefaultLanguage(state, action) {
      state.defaultLanguage = action.payload.row || null;
    },
    createOneLanguage(state, action) {
      state.languages = [...state.languages, action.payload.row];
    },
    updateOneLanguage(state, action) {
      state.languages = state.languages.map(item => {
        if (item.languageId !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },

    removeOneLanguage(state, action) {
      state.languages = state.languages.filter(
        item => item.languageId !== action.payload.id
      );
    }
  }
});

export const {
  getAllLanguage,
  getOneLanguage,
  createOneLanguage,
  updateOneLanguage,
  setOneDefaultLanguage,
  removeOneLanguage
} = languageSlice.actions;
export default languageSlice.reducer;
