import { useDispatch, useSelector } from 'store';

import AuthService from '../services/AuthService';
import {
  TAuthUser,
  TChangePassword,
  TForgotPassword,
  TLogin,
  TResetPassword
} from '../types/auth';
import { setLogin, setLogout, setProfile } from '../store/reducers/authSlice';

const useAuthHook = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector(state => state.auth);

  const login = async (data: TLogin) => {
    const resp = await AuthService.login(data);
    const token: string = resp?.data?.token || '';
    const user: TAuthUser = {
      email: resp?.data?.email as string,
      username: resp?.data?.username as string,
      userId: resp?.data?.userId as string
    };

    const scopes = resp?.data?.scopes || [];
    dispatch(
      setLogin({
        user,
        token,
        scopes
      })
    );
    window.location.reload();
  };
  const logout = async () => {
    AuthService.logout().catch(e => console.log('Logout Error:', e));
    dispatch(setLogout());
    // dispatch({ type: 'RESET_STATE' });
  };
  const forgotPassword = async (data: TForgotPassword) => {
    try {
      await AuthService.forgotPassword(data);
    } catch (e: unknown) {
      console.log('Forgot Password:', e);
      throw e;
    }
  };
  const resetPassword = async (data: TResetPassword) => {
    try {
      await AuthService.resetPassword(data);
    } catch (e: unknown) {
      console.log('Reset Password:', e);
      throw e;
    }
  };

  const changePassword = async (data: TChangePassword) => {
    try {
      await AuthService.changePassword(data);
    } catch (e: unknown) {
      console.log('Change Password:', e);
      throw e;
    }
  };
  const updateProfile = async (data: TAuthUser) => {
    try {
      await AuthService.updateProfile(data);
      dispatch(setProfile({ user: data }));
    } catch (e: unknown) {
      console.log('Change Password:', e);
      throw e;
    }
  };

  return {
    isAuthenticated,
    user,
    login,
    logout,
    updateProfile,
    forgotPassword,
    resetPassword,
    changePassword
  };
};

export default useAuthHook;
