import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchBox from 'components/common/SearchBox';
import usa from '../../assets/img/country/usa.png';
import { Col, Form, Row } from 'react-bootstrap';

import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import MembersTable, {
  membersTablecolumns
} from 'components/tables/MembersTable';
import { members } from 'data/members';
import { Link } from 'react-router-dom';
import FilterTab, { FilterTabItem } from 'components/common/FilterTab';

const tabItems: FilterTabItem[] = [
  {
    label: 'All',
    value: 'all',
    count: 68817
  },
  {
    label: 'Published',
    value: 'published',
    count: 70348
  },
  {
    label: 'Drafts',
    value: 'drafts',
    count: 17
  }
];

const ContentType = () => {
  const table = useAdvanceTable({
    data: members,
    columns: membersTablecolumns,
    selection: false,
    sortable: true,
    pagination: true,
    pageSize: 10
  });

  return (
    <>
      <div className="mb-9">
        <div className="mx-n4 px-4 py-1 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent top-section mb-5">
          <div></div>
          <div className="top-section__lang d-flex align-items-center">
            <img src={usa} alt="" />
            <Form.Select aria-label="Default Language">
              <option value="1">English</option>
              <option value="2">ខ្មែរ</option>
            </Form.Select>
          </div>
        </div>

        <h2 className="mb-5">Content Type</h2>

        <FilterTab tabItems={tabItems} className="mb-3" />

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox placeholder="Search By Name" />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Link to="/content-type/add" className="btn btn-primary">
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add New
                </Link>
              </Col>
            </Row>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </>
  );
};

export default ContentType;
