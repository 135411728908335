import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import {
  faPlus,
  faSave,
  faTrash,
  faUpload
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TinymceEditor from 'components/base/TinymceEditor';
import mainCoverImage from 'assets/img/placeholder-img.png';
import { FieldArray, Formik } from 'formik';
import { CmsContentSchema } from '../../../validation-schema';
import { TCmsContent } from '../../../types/cms-content';
import { toast } from 'react-toastify';
import useCmsContentHook from '../../../hooks/modules/usecmsContentHook';
import { useLocation, useNavigate } from 'react-router-dom';
import { confirmAlert } from '../../../components/common/ConfirmAlert';
import { useFileManager } from '../../../hooks/useFileMangerHook';
import LanguageContainer from '../../../components/base/LanguageContainer';
import useLanguageHook from '../../../hooks/modules/useLanguageHook';
import DefaultFormSection from './DefaultFormSection';

const PageAdd = () => {
  const location = useLocation();
  const { slug } = location.state || {};
  const { showMediaManager } = useFileManager();
  const { defaultLanguage } = useLanguageHook();

  const [loading, setLoading] = useState(false);
  const [cmsContent, setCmsContent] = useState<TCmsContent | null>(null);
  const { createCmsContent, updateCmsContent, fetchOneCmsContent } =
    useCmsContentHook();
  const navigate = useNavigate();

  const initialValues: TCmsContent = useMemo(() => {
    if (cmsContent) {
      const tempCmsContent = JSON.parse(JSON.stringify(cmsContent));
      // if (
      //   !tempCmsContent?.detail?.accordions?.length ||
      //   tempCmsContent?.detail?.accordions?.length <= 0
      // ) {
      //   tempCmsContent.detail = {
      //     accordions: [{ title: '', description: '' }]
      //   };
      // }
      return tempCmsContent;
    } else {
      return {
        languageId: defaultLanguage?.languageId,
        slug: slug || '',
        title: '',
        description: '',
        detail: {
          // accordions: [{ title: '', description: '' }]
        }
      };
    }
  }, [cmsContent, defaultLanguage]);

  const handleBack = () => {
    navigate('/pages');
  };
  useEffect(() => {
    if (defaultLanguage && slug) {
      setLoading(true);
      fetchOneCmsContent(slug, defaultLanguage?.languageId as number)
        .then(data => setCmsContent(data))
        .catch(e => {
          setCmsContent(null);
          console.log(e);
        })
        .finally(() => setLoading(false));
    }
  }, [defaultLanguage, slug]);
  const handleOnSubmit = (values: TCmsContent) => {
    setLoading(true);

    if (values.cmsContentId) {
      updateCmsContent(values.cmsContentId, values)
        .then(() => {
          toast.success('Update Page.');
          handleBack();
        })
        .catch(e => {
          toast.error('Error');
          console.log(e);
        })
        .finally(() => setLoading(false));
    } else {
      createCmsContent(values)
        .then(() => {
          toast.success('Create Page.');
          handleBack();
        })
        .catch(e => {
          toast.error('Error');
          console.log(e);
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <>
      {<LanguageContainer />}
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={CmsContentSchema}
        onSubmit={handleOnSubmit}
      >
        {({
          values,
          isSubmitting,

          handleChange,

          isValid,
          setFieldValue,

          handleSubmit
        }) => {
          const handleImageSelection = async () => {
            const media = await showMediaManager();
            await setFieldValue(`mediaId`, `${media.mediaId}`);
            await setFieldValue(`mediaUrl`, media?.mediaUrl);
          };
          const imgSrc = values?.mediaUrl || mainCoverImage;
          return (
            <>
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="mb-4 mb-xl-6 mb-xxl-4 gy-3 justify-content-between">
                  <Col xs="auto">
                    <h2>
                      {' '}
                      {cmsContent?.cmsContentId ? 'Edit' : 'Add New'} Page&nbsp;
                      {cmsContent ? (
                        <small className="text-primary">
                          ({cmsContent.title})
                        </small>
                      ) : (
                        ''
                      )}
                    </h2>
                  </Col>
                  <Col xs="auto">
                    <div className="d-flex gap-3">
                      <Button variant="phoenix-secondary" onClick={handleBack}>
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting || loading || !isValid}
                      >
                        <FontAwesomeIcon icon={faSave} className="me-2" />
                        {cmsContent?.cmsContentId ? 'Update' : 'Save'}
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-5">
                    <div className="d-flex align-items-end mb-4 w-100 position-relative upload-cover-img">
                      <img src={imgSrc} alt="cover image" className="rounded" />
                      <div className="ps-3 pb-3 rounded-1 position-absolute bottom-0 start-0">
                        <Button
                          variant="phoenix-secondary"
                          onClick={() => {
                            handleImageSelection().catch(e => console.log(e));
                          }}
                        >
                          <FontAwesomeIcon icon={faUpload} className="me-2" />
                          Upload Cover Image
                        </Button>
                      </div>
                    </div>
                    <DefaultFormSection slug={slug} />

                    <Row>
                      <Col>
                        <Card className="mt-5">
                          <Card.Body>
                            <FieldArray name="detail.accordions">
                              {({ remove, push }) => (
                                <>
                                  <Card.Title className="d-flex justify-content-between align-items-center">
                                    <h4> Accordion Section</h4>
                                    <Button
                                      variant="phoenix-primary"
                                      className="p-2"
                                      onClick={() =>
                                        push({ title: '', description: '' })
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faPlus}
                                        className="me-2"
                                      />
                                      Add New
                                    </Button>
                                  </Card.Title>

                                  <div className="d-flex gap-3 flex-column">
                                    {values?.detail?.accordions?.map(
                                      (data, index) => (
                                        <div key={index}>
                                          <div className="border-top pt-3">
                                            <Row className="g-3">
                                              <Col md={12}>
                                                <FloatingLabel
                                                  controlId="floatingInput"
                                                  label="Title"
                                                >
                                                  <Form.Control
                                                    value={
                                                      values?.detail
                                                        ?.accordions?.[index]
                                                        .title
                                                    }
                                                    type="text"
                                                    name={`detail.accordions.${index}.title`}
                                                    onChange={handleChange}
                                                    placeholder="Title"
                                                  />
                                                </FloatingLabel>
                                              </Col>
                                              <Col md={12}>
                                                <TinymceEditor
                                                  value={
                                                    values?.detail
                                                      ?.accordions?.[index]
                                                      .description
                                                  }
                                                  onChange={content => {
                                                    setFieldValue(
                                                      `detail.accordions.${index}.description`,
                                                      content
                                                    );
                                                  }}
                                                  options={{
                                                    height: '15rem',
                                                    placeholder:
                                                      'Write a description here...'
                                                  }}
                                                />
                                              </Col>
                                            </Row>
                                          </div>
                                          <div className="d-flex justify-content-end  mt-2">
                                            <Button
                                              variant="phoenix-danger"
                                              className="p-2"
                                              onClick={() => {
                                                confirmAlert({
                                                  title: 'Delete',
                                                  message: `Are you sure want to delete?`
                                                }).then(resp => {
                                                  if (resp) remove(index);
                                                });
                                              }}
                                              disabled={index === 0}
                                            >
                                              <FontAwesomeIcon
                                                icon={faTrash}
                                                className="me-2"
                                              />
                                              Delete
                                            </Button>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </>
                              )}
                            </FieldArray>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default PageAdd;
