import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';

interface FooterProps {
  className?: string;
}

const Footer = ({ className }: FooterProps) => {
  return (
    <footer className={classNames(className, 'footer')}>
      <Row className="g-1 justify-content-center align-items-center h-100">
        <Col xs={12} sm="auto" className="text-center">
          <p className="mb-0 mt-2 mt-sm-0 fs-9">
            {new Date().getFullYear()} &copy; Copyright Cambodian FTA Portal.
            All Right Reserved.
          </p>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
