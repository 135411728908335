import {
  Button,
  Col,
  FloatingLabel,
  Form,
  Modal,
  ModalProps,
  Nav,
  Offcanvas,
  Row,
  Tab
} from 'react-bootstrap';
import Dropzone from './Dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAdd,
  faEdit,
  faList,
  faTableCellsLarge,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import SearchBox from '../common/SearchBox';
import React, { useEffect, useRef, useState } from 'react';
import useMediaHook from '../../hooks/modules/useMediaHook';
import { TMedia } from '../../types/module';
import useAuthHook from '../../hooks/useAuthHook';
import { confirmAlert } from '../common/ConfirmAlert';
import { toast } from 'react-toastify';
import PhoenixLoader from '../common/PhoenixLoader';
import { fileNameWithoutExt, getFileIcon, isImage } from '../../helpers/utils';

interface FileManagerProps {
  modal: ModalProps;
  onSelectedMedia: (media: TMedia) => void;
  onClose: () => void;
}

const FileManager = ({ modal, onClose, onSelectedMedia }: FileManagerProps) => {
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const dropzoneRef = useRef<any>();

  const [show, setShow] = useState(false);
  const [altText, setAltText] = useState<string>();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { fetchAllMedia, createMedia, medias, deleteMedia } = useMediaHook();
  const { isAuthenticated } = useAuthHook();
  const [file, selectedFile] = useState<File | null>(null);
  useEffect(() => {
    setAltText('');
    selectedFile(null);
    if (isAuthenticated) {
      if (medias.length <= 0) {
        setLoading(true);
        fetchAllMedia()
          .catch(e => console.log(e))
          .finally(() => setLoading(false));
      }
    }
  }, [modal, isAuthenticated]);

  // eslint-disable-next-line
  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    const formData: TMedia = {
      altText: altText || '',
      fileData: 'Test',
      fileName: 'Test',
      fileType: 'Test',
      status: 'active',

      file: file as File
    };
    if (file && formData) {
      createMedia(formData)
        .then(() => {
          selectedFile(null);
        })
        .catch(e => console.log(e))
        .finally(() => {
          setAltText('');
          if (dropzoneRef.current) {
            dropzoneRef.current.handleResetFile();
          }
        });
    }
  };
  const handleOnDelete = (data: TMedia) => {
    confirmAlert({
      title: 'Delete',
      message: `Are you sure want to remove a selected file?`
    }).then(resp => {
      if (resp && data && data.mediaId) {
        setLoading(true);
        deleteMedia(data.mediaId)
          .then(() => {
            toast.success('Delete File');
          })
          .catch(e => {
            toast.error('Error');
            console.log(e);
          })
          .finally(() => setLoading(false));
      }
    });
  };
  const renderGrid = () => {
    if (loading) {
      return <PhoenixLoader />;
    } else {
      return (
        <Row className="g-3">
          {medias.map((data, num) => {
            return (
              <Col md={2} key={`media-grid-${num}`}>
                {/* When selected active class: media--selected */}
                <div
                  className="media rounded cursor-pointer"
                  onClick={() => onSelectedMedia(data)}
                >
                  {isImage(data?.mediaUrl as string) ? (
                    <img
                      src={data?.mediaUrl}
                      alt={data?.altText}
                      className="rounded"
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="fs-1"
                      icon={getFileIcon(data?.mediaUrl as string)}
                    />
                  )}

                  <div className="media__name fs-9">
                    <p className="mb-0">{data?.altText}</p>
                    <span>{data?.altText}</span>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      );
    }
  };
  const renderList = () => {
    if (loading) {
      return <PhoenixLoader />;
    } else {
      return (
        <>
          <table className="fs-9 mb-0 border-top border-translucent scrollbar table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Author</th>
                <th>Uploaded To</th>
                <th>Uploaded Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {medias.map((data, i) => (
                <tr key={`media-list-${i}`}>
                  <td className="align-middle">
                    <div className="d-flex">
                      {isImage(data?.mediaUrl as string) ? (
                        <img
                          style={{ height: '50px', width: '50px' }}
                          src={data?.mediaUrl}
                          alt={data?.altText}
                          className="me-2"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={getFileIcon(data?.mediaUrl as string)}
                        />
                      )}

                      {data?.altText}
                    </div>
                  </td>
                  <td className="align-middle"> {data?.createdBy}</td>
                  <td className="align-middle">{data?.createdAt}</td>
                  <td className="align-middle">
                    <div className="d-flex align-items-center justify-content-end gap-2">
                      <Button
                        variant="phoenix-secondary"
                        size="sm"
                        onClick={handleShow}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>

                      <Button
                        variant="phoenix-secondary"
                        size="sm"
                        onClick={() => handleOnDelete(data)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Offcanvas
            placement="end"
            show={show}
            onHide={handleClose}
            style={{ zIndex: '9999' }}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <h4 className="mb-0">Media Details</h4>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="d-flex flex-column gap-3">
                <img src={''} alt="media" className="rounded thumbnail-img" />
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex gap-2 fs-9">
                    <span className="fw-bold">Uploaded on:</span>
                    <span>2024-04-24</span>
                  </div>
                  <div className="d-flex gap-2 fs-9">
                    <span className="fw-bold">Uploaded by:</span>
                    <span>admin</span>
                  </div>
                  <div className="d-flex gap-2 fs-9">
                    <span className="fw-bold">Filename:</span>
                    <span>2024-04-24</span>
                  </div>
                  <div className="d-flex gap-2 fs-9">
                    <span className="fw-bold">Uploaded to:</span>
                    <a href="">https://cambodiafta.gov.kh/origin-resources</a>
                  </div>
                  <hr />
                  <Form>
                    <Row>
                      <Col md={12}>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Alternative Text"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Alternative Text"
                          />
                        </FloatingLabel>
                      </Col>
                      <Col md={12}>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Title"
                          className="mb-3"
                        >
                          <Form.Control type="text" placeholder="Title" />
                        </FloatingLabel>
                      </Col>
                      <Col>
                        <Row className="g-2">
                          <Col>
                            <Button
                              variant="phoenix-secondary"
                              className="w-100"
                            >
                              Cancel
                            </Button>
                          </Col>
                          <Col>
                            <Button variant="primary" className="w-100">
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </>
      );
    }
  };
  return (
    <Modal
      {...modal}
      onHide={onClose}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Select Media
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleOnSubmit}>
          <div>
            <div className="d-flex flex-column mb-4 w-100">
              <h6 className="mb-2">Add Media</h6>
              <Dropzone
                ref={dropzoneRef}
                className="w-100"
                noPreview={true}
                onDrop={acceptedFiles => {
                  selectedFile(acceptedFiles?.[0] || null);

                  setAltText(
                    fileNameWithoutExt(acceptedFiles?.[0]?.name) || ''
                  );
                }}
              />
              <Row>
                <Col md={12} className="mt-4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Alternative Text"
                    className="mb-3"
                  >
                    <Form.Control
                      onChange={e => {
                        setAltText(e?.target?.value || '');
                      }}
                      type="text"
                      value={altText}
                      placeholder="Alternative Text"
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <div className="d-flex justify-content-end mt-4">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!altText || !file || loading}
                >
                  <FontAwesomeIcon icon={faAdd} className="me-2" />
                  Upload
                </Button>
              </div>
            </div>
            <div className="media-block">
              <Tab.Container id="basic-tabs-example" defaultActiveKey="grid">
                <Nav
                  variant="underline"
                  className="mb-3 justify-content-between flex-row-reverse"
                >
                  <div className="d-flex gap-1">
                    <Nav.Item>
                      <Nav.Link eventKey="grid">
                        <Button variant="phoenix-secondary" size="sm">
                          <FontAwesomeIcon icon={faTableCellsLarge} />
                        </Button>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="list">
                        <Button variant="phoenix-secondary" size="sm">
                          <FontAwesomeIcon icon={faList} />
                        </Button>
                      </Nav.Link>
                    </Nav.Item>
                  </div>
                  <div>
                    <SearchBox />
                  </div>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="grid">{renderGrid()}</Tab.Pane>
                  <Tab.Pane eventKey="list">{renderList()}</Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="phoenix-secondary" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default FileManager;
