import { useDispatch, useSelector } from '../../store';
import { TMedia } from '../../types/module';
import MediaService from '../../services/MediaService';
import {
  createOneMedia,
  getAllMedia,
  getOneMedia,
  removeOneMedia,
  updateOneMedia
} from '../../store/reducers/modules/mediaSlice';

const useMediaHook = () => {
  const dispatch = useDispatch();
  const { medias, media } = useSelector(state => state.media);

  const fetchAllMedia = async (): Promise<TMedia[]> => {
    try {
      const resp = await MediaService.fetchAll();
      let results = resp?.data || [];
      results = await Promise.all(
        results.map(async (data: TMedia) => {
          let mediaUrl = null;
          if (data?.mediaId) {
            try {
              const responseMedia = await MediaService.fetchUrl(data.mediaId);
              mediaUrl = responseMedia.data;
            } catch (e) {
              console.log(e);
            }
          }

          return {
            ...data,
            mediaUrl
          };
        })
      );
      dispatch(getAllMedia({ results }));
      return results;
    } catch (e) {
      console.log('fetchAllMedia', e);
      throw e;
    }
  };
  const fetchOneMedia = async (data: TMedia): Promise<TMedia> => {
    try {
      const row = data || null;
      dispatch(getOneMedia({ row }));
      return row;
    } catch (e) {
      console.log('fetchOneMedia', e);
      throw e;
    }
  };
  const createMedia = async (data: TMedia): Promise<TMedia> => {
    try {
      const resp = await MediaService.create(data);
      const row = resp?.data || null;

      try {
        if (row?.mediaId) {
          const responseMedia = await MediaService.fetchUrl(row.mediaId);
          row.mediaUrl = responseMedia.data;
        }
      } catch (e) {
        console.log(e);
      }

      dispatch(createOneMedia({ row }));
      return row;
    } catch (e) {
      console.log('createMedia', e);
      throw e;
    }
  };
  const updateMedia = async (id: number, data: TMedia) => {
    try {
      await MediaService.update(id, data);
      dispatch(updateOneMedia({ id, row: data }));
    } catch (e) {
      console.log('updateMedia', e);
      throw e;
    }
  };
  const deleteMedia = async (id: number) => {
    try {
      await MediaService.destroy(id);
      dispatch(removeOneMedia({ id }));
    } catch (e) {
      console.log('deleteMedia', e);
      throw e;
    }
  };
  const fetchMedialUrl = async (id: number | string) => {
    try {
      const resp = await MediaService.fetchUrl(id);
      return resp?.data || '';
    } catch (e) {
      console.log('fetchMedialUrl', e);
      throw e;
    }
  };
  return {
    fetchAllMedia,
    fetchOneMedia,
    createMedia,
    updateMedia,
    deleteMedia,
    fetchMedialUrl,
    medias,
    media
  };
};

export default useMediaHook;
