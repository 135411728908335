import {
  faEye,
  faEyeSlash,
  faKey,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';

import { useState } from 'react';
import { TLogin } from '../../../types/auth';
import { TAlert } from '../../../types';
import AlertMessage from '../../common/AlertMessage';
import useAuthHook from '../../../hooks/useAuthHook';
import { SignInSchema } from '../../../validation-schema/AuthSchema';

const SignInForm = () => {
  const savedusername = localStorage.getItem('rememberedusername') || '';
  const [rememberMe, setRememberMe] = useState(!!savedusername);
  const [showPassword, setShowPassword] = useState(false);
  const [alert, setAlert] = useState<TAlert | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const initialValues: TLogin = {
    username: '',
    password: ''
  };
  const { login } = useAuthHook();
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleRememberMeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(e.target.checked);
  };

  const onSubmit = async (values: TLogin) => {
    setLoader(true);

    if (rememberMe) {
      localStorage.setItem('rememberedusername', values.username);
    } else {
      localStorage.removeItem('rememberedusername');
    }

    login(values)
      .catch(e => {
        if (e?.response?.status == '401') {
          setAlert({
            type: 'ERROR',
            message: 'Invalid username or Password'
          });
        } else {
          setAlert({
            type: 'ERROR',
            message: 'Invalid username or Password'
          });
        }
      })
      .finally(() => setLoader(false));
  };
  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">Sign In</h3>
        <p className="text-body-tertiary">Get access to your account</p>
      </div>
      {alert && <AlertMessage type={alert.type} message={alert.message} />}
      <Formik
        initialValues={initialValues}
        validationSchema={SignInSchema}
        onSubmit={onSubmit}
      >
        {({
          isSubmitting,
          values,
          handleBlur,
          handleChange,
          errors,
          touched,
          handleSubmit
        }) => {
          return (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3 text-start">
                <Form.Label htmlFor="email">Email/Username</Form.Label>
                <div className="form-icon-container">
                  <Form.Control
                    id="username"
                    type="username"
                    name="username"
                    className={`form-icon-input ${
                      touched.username && errors.username ? 'is-invalid' : ''
                    }`}
                    placeholder="username or Email"
                    value={values.username}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.username && errors.username && (
                    <Form.Control.Feedback type="invalid">
                      {errors.username}
                    </Form.Control.Feedback>
                  )}

                  <FontAwesomeIcon
                    icon={faUser}
                    className="text-body fs-9 form-icon"
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3 text-start">
                <Form.Label htmlFor="password">Password</Form.Label>
                <div className="form-icon-container">
                  <Form.Control
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    className={`form-icon-input ${
                      touched.password && errors.password ? 'is-invalid' : ''
                    }`}
                    value={values.password}
                    placeholder="Password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.password && errors.password && (
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  )}

                  <FontAwesomeIcon
                    icon={faKey}
                    className="text-body fs-9 form-icon"
                  />
                  <FontAwesomeIcon
                    onClick={handleClickShowPassword}
                    style={{ left: '90%', cursor: 'pointer' }}
                    icon={showPassword ? faEye : faEyeSlash}
                    className="text-body fs-9 form-icon"
                  />
                </div>
              </Form.Group>
              <Row className="flex-between-center mb-7">
                <Col xs="auto">
                  <Form.Check type="checkbox" className="mb-0">
                    <Form.Check.Input
                      type="checkbox"
                      name="remember-me"
                      id="remember-me"
                      checked={rememberMe}
                      onChange={handleRememberMeChange}
                    />
                    <Form.Check.Label htmlFor="remember-me" className="mb-0">
                      Remember me
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
                <Col xs="auto">
                  <Link
                    to={`/auth/forgot-password`}
                    className="fs-9 fw-semibold"
                  >
                    Forgot Password?
                  </Link>
                </Col>
              </Row>
              <Button
                disabled={isSubmitting || loader}
                type="submit"
                variant="primary"
                className="w-100 mb-3"
              >
                Sign In
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default SignInForm;
