import {
  faAdd,
  faArrowsUpDown,
  faClockRotateLeft,
  faEdit,
  faEye,
  faSave,
  faTrash,
  faUpload
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';

import Avatar from 'components/base/Avatar';
import team33 from 'assets/img/team/33.webp';
import Button from 'components/base/Button';
import Dropzone from 'components/base/Dropzone';
import usa from '../../assets/img/country/usa.png';
import AvatarUpload from 'components/common/AvatarUpload';
import avatar from 'assets/img/team/20.webp';

const Keynotes = () => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <div className="mx-n4 px-4 py-1 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent top-section mb-5">
        <div className="d-flex align-items-center gap-2 text-body-tertiary fs-9">
          <FontAwesomeIcon
            icon={faClockRotateLeft}
            className="text-body-quaternary"
          />
          Last updated: 2024-04-24
        </div>
        <div className="top-section__lang d-flex align-items-center">
          <img src={usa} alt="" />
          <Form.Select aria-label="Default Language">
            <option value="1">English</option>
            <option value="2">ខ្មែរ</option>
          </Form.Select>
        </div>
      </div>
      <Row className="mb-4 mb-xl-6 mb-xxl-4 gy-3 justify-content-between">
        <Col xs="auto">
          <h2>Keynotes</h2>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-5">
          {[1, 2, 3, 4].map(num => {
            return (
              <Card key={num} className="mb-3">
                <Card.Body>
                  <Row className="align-items-center g-3 text-center text-xxl-start mb-3">
                    <Col xs={12} xxl="auto">
                      <Avatar
                        size="4xl"
                        src={team33}
                        className="d-inline-block"
                      />
                    </Col>
                    <Col xs={12} sm="auto" className="flex-1">
                      <h3 className="fw-bolder mb-2">
                        Her Excellency Cham Nimul
                      </h3>
                      <p className="mb-0">Chair Person, Ministry of Commerce</p>
                      <Card.Text>
                        <small className="text-muted">
                          Last updated 3 mins ago
                        </small>
                      </Card.Text>
                    </Col>
                  </Row>
                  <Row className="align-items-center g-3 text-center text-xxl-start">
                    <Col xs={12} xxl="auto">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Show/hide Keynote"
                      />
                    </Col>
                    <Col xs={12} sm="auto" className="flex-1">
                      <div className="d-flex align-items-center justify-content-end gap-2">
                        <Button variant="phoenix-secondary" size="sm">
                          <FontAwesomeIcon icon={faArrowsUpDown} />
                        </Button>
                        <Button variant="phoenix-secondary" size="sm">
                          <FontAwesomeIcon icon={faEye} />
                        </Button>
                        <Button
                          variant="phoenix-secondary"
                          size="sm"
                          onClick={() => setModalShow(true)}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                        <Button variant="phoenix-secondary" size="sm">
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            );
          })}
        </Col>
        <Col md={6} className="mb-5">
          <Form>
            <h3 className="mb-5">Add New Keynotes</h3>
            <div className="d-flex align-items-end mb-4 w-100">
              <Dropzone
                className="w-100"
                onDrop={acceptedFiles => console.log(acceptedFiles)}
              />
            </div>
            <div>
              <h4 className="mb-3">Personal Information</h4>
              <Row>
                <Col md={6} className="mb-5">
                  <FloatingLabel controlId="floatingSelect" label="Title">
                    <Form.Select aria-label="Floating label select example">
                      <option>Select</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col md={6} className="mb-5">
                  <FloatingLabel controlId="floatingInput" label="Full Name">
                    <Form.Control type="text" placeholder="Full Name" />
                  </FloatingLabel>
                </Col>
                <Col md={6} className="mb-5">
                  <FloatingLabel controlId="floatingSelect" label="Designation">
                    <Form.Select aria-label="Floating label select example">
                      <option>Select</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col md={6} className="mb-5">
                  <FloatingLabel controlId="floatingSelect" label="Industry">
                    <Form.Select aria-label="Floating label select example">
                      <option>Select</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>
              </Row>
              <h4 className="mb-3">Message</h4>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Your Message Here..."
                  rows={6}
                />
              </Form.Group>
              <Row className="align-items-center g-3 text-center text-xxl-start">
                <Col xs={12} xxl="auto">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="Show/hide Keynote"
                  />
                </Col>
                <Col xs={12} sm="auto" className="flex-1">
                  <div className="d-flex align-items-center justify-content-end gap-2">
                    <Button variant="phoenix-secondary">Cancel</Button>
                    <Button variant="primary">
                      <FontAwesomeIcon icon={faAdd} className="me-2" />
                      Add
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Form>
        </Col>
      </Row>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Keynote
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="d-flex align-items-center gap-4 mb-4 w-100">
              <AvatarUpload src={avatar} size="5xl" />
              <div className="d-flex gap-2">
                <Button variant="phoenix-secondary" size="sm">
                  <FontAwesomeIcon icon={faUpload} />
                </Button>
                <Button variant="phoenix-secondary" size="sm">
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </div>
            </div>
            <div className="">
              <h4 className="mb-3">Personal Information</h4>
              <Row>
                <Col md={6} className="mb-5">
                  <FloatingLabel controlId="floatingSelect" label="Title">
                    <Form.Select aria-label="Floating label select example">
                      <option>Select</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col md={6} className="mb-5">
                  <FloatingLabel controlId="floatingInput" label="Full Name">
                    <Form.Control type="text" placeholder="Full Name" />
                  </FloatingLabel>
                </Col>
                <Col md={6} className="mb-5">
                  <FloatingLabel controlId="floatingSelect" label="Designation">
                    <Form.Select aria-label="Floating label select example">
                      <option>Select</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col md={6} className="mb-5">
                  <FloatingLabel controlId="floatingSelect" label="Industry">
                    <Form.Select aria-label="Floating label select example">
                      <option>Select</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>
              </Row>
              <h4 className="mb-3">Message</h4>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Your Message Here..."
                  rows={6}
                />
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="phoenix-secondary">Cancel</Button>
          <Button variant="primary">
            <FontAwesomeIcon icon={faSave} className="me-2" />
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Keynotes;
