import { createSlice } from '@reduxjs/toolkit';
import { TCountry } from '../../../types/module';

interface CountryState {
  countries: TCountry[];
  country: TCountry;
}

const initialState: CountryState = {
  countries: [],
  country: <TCountry>{}
};

const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    getAllCountry(state, action) {
      state.countries = action.payload.results || [];
    },
    getOneCountry(state, action) {
      state.country = action.payload.row || null;
    },
    createOneCountry(state, action) {
      state.countries = [...state.countries, action.payload.row];
    },
    updateOneCountry(state, action) {
      state.countries = state.countries.map(item => {
        if (item.countryId !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },

    removeOneCountry(state, action) {
      state.countries = state.countries.filter(
        item => item.countryId !== action.payload.id
      );
    }
  }
});

export const {
  getAllCountry,
  getOneCountry,
  createOneCountry,
  updateOneCountry,
  removeOneCountry
} = countrySlice.actions;
export default countrySlice.reducer;
