import { createSlice } from '@reduxjs/toolkit';
import { RooQuestion } from 'types/fta';

interface RooState {
  rooList: RooQuestion[];
  roo: RooQuestion;
}

const initialState: RooState = {
  rooList: [],
  roo: <RooQuestion>{}
};

const rooSlice = createSlice({
  name: 'roo',
  initialState,
  reducers: {
    getAllRoo(state, action) {
      state.rooList = action.payload.results || [];
    },
    getOneRoo(state, action) {
      state.roo = action.payload.row || null;
    },
    createOneRoo(state, action) {
      state.rooList = [...state.rooList, action.payload.row];
    },
    updateOneRoo(state, action) {
      state.rooList = state.rooList.map(item => {
        if (item.rooId !== action.payload.rooId) return item;
        else return { ...item, ...action.payload.row };
      });
    },
    removeOneRoo(state, action) {
      state.rooList = state.rooList.filter(
        item => item.rooId !== action.payload.rooId
      );
    }
  }
});

export const {
  getAllRoo,
  getOneRoo,
  createOneRoo,
  updateOneRoo,
  removeOneRoo
} = rooSlice.actions;
export default rooSlice.reducer;
