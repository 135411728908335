import { TLanguage } from '../types/module';
import api from '../utils/api';

const authEndpoint: string = `v1/language/languages`;

const fetchAll = () => {
  return api.get(`${authEndpoint}`);
};
const fetchOne = (id: number) => {
  return api.get(`${authEndpoint}/${id}`);
};

const create = (data: TLanguage) => {
  return api.post(`${authEndpoint}`, data);
};

const update = (id: number, data: TLanguage) => {
  return api.put(`${authEndpoint}/${id}`, data);
};
const destroy = (id: number) => {
  return api.delete(`${authEndpoint}/${id}`);
};

export default {
  fetchAll,
  fetchOne,
  create,
  update,
  destroy
};
