import MainLayout from 'layouts/MainLayout';
import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import Error404 from 'pages/error/Error404';
import Error403 from 'pages/error/Error403';
import Error500 from 'pages/error/Error500';
import App from 'App';
import SignIn from 'pages/authentication/card/SignIn';
import SignUp from 'pages/authentication/card/SignUp';
import ForgotPassword from 'pages/authentication/card/ForgotPassword';
import SignOut from 'pages/authentication/card/SignOut';
import ResetPassword from 'pages/authentication/card/ResetPassword';
import ContentType from 'pages/content-type';
import ContentTypeAdd from 'pages/content-type/add';
import Keynotes from 'pages/keynotes';
import PageAdd from 'pages/cms/add';
import AuthGuard from './utils/route-guard/AuthGuard';
import GuestGuard from './utils/route-guard/GuestGuard';
import { lazy, Suspense } from 'react';
import PhoenixLoader from './components/common/PhoenixLoader';
import User from 'pages/user';

const CMSListPage = lazy(() => import('pages/cms'));
const CMSAddPage = lazy(() => import('pages/cms/add'));
const HomePage = lazy(() => import('pages/home'));
const AgreementPage = lazy(() => import('pages/agreements'));
const FTEPage = lazy(() => import('pages/first-time-exporter'));
const PreferentialTreatmentPage = lazy(
  () => import('pages/preferential-treatment')
);
const SettingPage = lazy(() => import('pages/settings'));
const ProfilePage = lazy(() => import('pages/profile'));
const FtaListPage = lazy(() => import('pages/fta'));
const FtaAddPage = lazy(() => import('pages/fta/add'));
const FtaDetailPage = lazy(() => import('pages/fta/detail'));
const FtaPsrUploadPage = lazy(() => import('pages/fta/psr/upload'));
const FtaTrsUploadPage = lazy(() => import('pages/fta/trs/upload'));
const FtaRooUploadPage = lazy(() => import('pages/fta/roo/upload'));
const CountryPage = lazy(() => import('pages/country'));
const CountryMfnPage = lazy(() => import('pages/country/mfn'));
const CompanyPage = lazy(() => import('pages/company'));

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <MainLayoutProvider>
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="/fta" replace />
          },

          {
            path: '/fta',
            children: [
              {
                path: '/fta',
                element: (
                  <Suspense fallback={<PhoenixLoader />}>
                    <FtaListPage />
                  </Suspense>
                )
              },
              {
                path: 'add/:id?',
                element: (
                  <Suspense fallback={<PhoenixLoader />}>
                    <FtaAddPage />
                  </Suspense>
                )
              },
              {
                path: 'detail/:id',
                element: (
                  <Suspense fallback={<PhoenixLoader />}>
                    <FtaDetailPage />
                  </Suspense>
                )
              },
              {
                path: 'psr/upload/:ftaId',
                element: (
                  <Suspense fallback={<PhoenixLoader />}>
                    <FtaPsrUploadPage />
                  </Suspense>
                )
              },
              {
                path: 'roo/upload/:ftaId',
                element: (
                  <Suspense fallback={<PhoenixLoader />}>
                    <FtaRooUploadPage />
                  </Suspense>
                )
              },
              {
                path: 'trs/upload/:ftaId/:ftaCountryId',
                element: (
                  <Suspense fallback={<PhoenixLoader />}>
                    <FtaTrsUploadPage />
                  </Suspense>
                )
              }
            ]
          },
          {
            path: '/pages',
            children: [
              {
                path: '/pages',
                element: (
                  <Suspense fallback={<PhoenixLoader />}>
                    <CMSListPage />
                  </Suspense>
                )
              },
              {
                path: 'add',
                element: (
                  <Suspense fallback={<PhoenixLoader />}>
                    <CMSAddPage />
                  </Suspense>
                )
              }
            ]
          },
          {
            path: '/keynotes',
            element: <Keynotes />
          },
          {
            path: '/home',
            element: (
              <Suspense fallback={<PhoenixLoader />}>
                <HomePage />
              </Suspense>
            )
          },
          {
            path: '/agreements',
            element: (
              <Suspense fallback={<PhoenixLoader />}>
                <AgreementPage />
              </Suspense>
            )
          },
          {
            path: '/first-time-exporter',
            element: (
              <Suspense fallback={<PhoenixLoader />}>
                <FTEPage />
              </Suspense>
            )
          },
          {
            path: '/preferential-treatment',
            element: (
              <Suspense fallback={<PhoenixLoader />}>
                <PreferentialTreatmentPage />
              </Suspense>
            )
          },
          {
            path: '/country',
            children: [
              {
                path: '/country',
                element: (
                  <Suspense fallback={<PhoenixLoader />}>
                    <CountryPage />
                  </Suspense>
                )
              },

              {
                path: 'mfn',
                element: (
                  <Suspense fallback={<PhoenixLoader />}>
                    <CountryMfnPage />
                  </Suspense>
                )
              }
            ]
          },
          {
            path: '/user',
            children: [
              {
                path: '/user',
                element: <User />
              },
              {
                path: 'add',
                element: <PageAdd />
              }
            ]
          },
          {
            path: '/company',
            children: [
              {
                path: '/company',
                element: (
                  <Suspense fallback={<PhoenixLoader />}>
                    <CompanyPage />
                  </Suspense>
                )
              }
            ]
          },
          {
            path: '/content-type',
            children: [
              {
                path: '/content-type',
                element: <ContentType />
              },
              {
                path: 'add',
                element: <ContentTypeAdd />
              }
            ]
          },
          {
            path: '/settings',
            element: (
              <Suspense fallback={<PhoenixLoader />}>
                <SettingPage />
              </Suspense>
            )
          },
          {
            path: '/profile',
            element: (
              <Suspense fallback={<PhoenixLoader />}>
                <ProfilePage />
              </Suspense>
            )
          }
        ]
      },
      {
        path: '/pages/errors/',
        children: [
          {
            path: '404',
            element: <Error404 />
          },
          {
            path: '403',
            element: <Error403 />
          },
          {
            path: '500',
            element: <Error500 />
          }
        ]
      },
      {
        path: '/auth',
        children: [
          {
            path: 'sign-in',
            element: (
              <GuestGuard>
                <SignIn />
              </GuestGuard>
            )
          },
          {
            path: 'sign-up',
            element: (
              <GuestGuard>
                <SignUp />
              </GuestGuard>
            )
          },
          {
            path: 'sign-out',
            element: (
              <GuestGuard>
                <SignOut />
              </GuestGuard>
            )
          },
          {
            path: 'forgot-password',
            element: (
              <GuestGuard>
                <ForgotPassword />
              </GuestGuard>
            )
          },
          {
            path: 'reset-password',
            element: (
              <GuestGuard>
                <ResetPassword />
              </GuestGuard>
            )
          }
        ]
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
