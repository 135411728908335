import React from 'react';
import { faAdd, faEye, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchBox from 'components/common/SearchBox';
import { Button, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';

import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import MembersTable, {
  membersTablecolumns
} from 'components/tables/MembersTable';
import { members } from 'data/members';

const User = () => {
  const table = useAdvanceTable({
    data: members,
    columns: membersTablecolumns,
    selection: false,
    sortable: true,
    pagination: true,
    pageSize: 10
  });

  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <div className="mb-9">
        <h2 className="mb-5">User List</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox placeholder="Search By Name" />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                <Button variant="primary" onClick={() => setModalShow(true)}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add New
                </Button>
              </Col>
            </Row>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
        </AdvanceTableProvider>
      </div>

      {/* Add Country Modal */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="modal-md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={12}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Username"
                  className="mb-3"
                >
                  <Form.Control type="text" placeholder="Username" />
                </FloatingLabel>
              </Col>
              <Col md={12}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Email"
                  className="mb-3 form-icon-input"
                >
                  <Form.Control type="email" placeholder="Email" />
                </FloatingLabel>
              </Col>
              <Col md={12}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Password"
                  className="mb-3"
                >
                  <Form.Control type="password" placeholder="Password" />
                  <Button
                    variant="link"
                    className="p-3 position-absolute top-0 end-0"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      className="text-body-quaternary fs-10"
                    />
                  </Button>
                </FloatingLabel>
              </Col>
              <Col md={12}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Confirm Password"
                  className="mb-3"
                >
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                  />
                  <Button
                    variant="link"
                    className="p-3 position-absolute top-0 end-0"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      className="text-body-quaternary fs-10"
                    />
                  </Button>
                </FloatingLabel>
              </Col>
              <Col md="12">
                <FloatingLabel
                  controlId="floatingSelect"
                  label="Role"
                  className="mb-3"
                >
                  <Form.Select aria-label="Floating label select">
                    <option>Select</option>
                    <option value="Admin">Admin</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col md="12">
                <FloatingLabel
                  controlId="floatingSelect"
                  label="Status"
                  className="mb-3"
                >
                  <Form.Select aria-label="Floating label select">
                    <option>Select</option>
                    <option value="">Active</option>
                    <option value="">Inactive</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="phoenix-secondary"
            onClick={() => setModalShow(false)}
          >
            Cancel
          </Button>
          <Button variant="primary">
            <FontAwesomeIcon icon={faAdd} className="me-2" />
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default User;
