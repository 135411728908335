import { TCountry, TMedia } from '../types/module';
import api from '../utils/api';
import { TCmsContent } from '../types/cms-content';
import { TFta } from '../types/fta';

const authEndpoint: string = `Media`;
const headers = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
};

const fetchAll = () => {
  return api.get(`${authEndpoint}`);
};
const fetchUrl = (id: string | number) => {
  return api.get(`${authEndpoint}/${id}/url`);
};
const fetchOne = (id: number) => {
  return api.get(`${authEndpoint}/${id}`);
};

const create = (data: TMedia) => {
  return api.post(`${authEndpoint}`, data, headers);
};

const update = (id: number, data: TMedia) => {
  return api.put(`${authEndpoint}/${id}`, data);
};
const destroy = (id: number) => {
  return api.delete(`${authEndpoint}/${id}`);
};
const mappedMediaUrl = async (row: TCmsContent | TMedia | TFta | TCountry) => {
  if (row?.mediaId) {
    try {
      const responseMedia = await fetchUrl(row.mediaId);
      row.mediaUrl = responseMedia.data;
    } catch (e) {
      console.log(e);
    }
  }

  return row;
};

export default {
  fetchAll,
  fetchUrl,
  fetchOne,
  create,
  update,
  destroy,
  mappedMediaUrl
};
