import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import settingReducer from './modules/settingSlice';
import languageReducer from './modules/languageSlice';
import mediaReducer from './modules/mediaSlice';
import countryReducer from './modules/countrySlice';
import countryMfnReducer from './modules/countryMfnSlice';
import ftaTypeReducer from './modules/ftaTypeSlice';
import psrTypeReducer from './modules/psrTypeSlice';
import cmsContentReducer from './modules/cmsContentSlice';
import ftaReducer from './modules/ftaSlice';
import ftaCountryReducer from './modules/ftaCountrySlice';
import ftaPsrReducer from './modules/ftaPsrSlice';
import ftaTrsReducer from './modules/ftaTrsSlice';
import ftaRooReducer from './modules/rooSlice';
import companyReducer from './modules/companySlice';

const rootReducer = combineReducers({
  auth: authReducer,
  setting: settingReducer,
  media: mediaReducer,
  language: languageReducer,
  country: countryReducer,
  countryMfn: countryMfnReducer,
  ftaType: ftaTypeReducer,
  psrType: psrTypeReducer,
  fta: ftaReducer,
  ftaPsr: ftaPsrReducer,
  ftaTrs: ftaTrsReducer,
  ftaCountry: ftaCountryReducer,
  cmsContent: cmsContentReducer,
  roo: ftaRooReducer,
  company: companyReducer
});

export default rootReducer;
