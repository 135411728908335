import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { stringToSlug } from '../../../helpers/utils';
import TinymceEditor from '../../../components/base/TinymceEditor';
import React from 'react';
import { useFormikContext } from 'formik';
import { TCmsContent } from '../../../types/cms-content';

const DefaultFormSection = ({ slug }: { slug: string | null }) => {
  const { values, handleChange, setFieldValue, touched, handleBlur, errors } =
    useFormikContext<TCmsContent>();
  return (
    <Row>
      <Col md={8}>
        <FloatingLabel label="Title *" className="mb-3">
          <Form.Control
            id="title"
            type="text"
            name="title"
            className={`${touched.title && errors.title ? 'is-invalid' : ''}`}
            placeholder="Title"
            value={values.title || ''}
            onBlur={handleBlur}
            onChange={e => {
              const title = e?.target?.value || '';
              setFieldValue('title', title);
              if (!slug)
                setFieldValue('slug', title ? stringToSlug(title) : '');
            }}
          />
          {touched.title && errors.title && (
            <Form.Control.Feedback type="invalid">
              {errors.title}
            </Form.Control.Feedback>
          )}
        </FloatingLabel>
      </Col>
      <Col md={4}>
        <FloatingLabel label="Slug *" className="mb-3">
          <Form.Control
            id="slug"
            type="text"
            disabled={true}
            name="slug"
            className={`${touched.slug && errors.slug ? 'is-invalid' : ''}`}
            placeholder="Slug"
            value={values.slug || ''}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {touched.slug && errors.slug && (
            <Form.Control.Feedback type="invalid">
              {errors.slug}
            </Form.Control.Feedback>
          )}
        </FloatingLabel>
      </Col>
      <Col md={12}>
        <TinymceEditor
          value={values.description || ''}
          onChange={content => {
            setFieldValue('description', content);
          }}
          options={{
            height: '15rem',
            placeholder: 'Write a description here...'
          }}
        />
      </Col>
    </Row>
  );
};
export default DefaultFormSection;
