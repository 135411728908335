import { createSlice } from '@reduxjs/toolkit';
import { TFta } from '../../../types/fta';

interface FtaState {
  ftas: TFta[];
  fta: TFta;
}

const initialState: FtaState = {
  ftas: [],
  fta: <TFta>{}
};

const ftaSlice = createSlice({
  name: 'fta',
  initialState,
  reducers: {
    getAllFta(state, action) {
      state.ftas = action.payload.results || [];
    },
    getOneFta(state, action) {
      state.fta = action.payload.row || null;
    },
    createOneFta(state, action) {
      state.ftas = [...state.ftas, action.payload.row];
    },
    updateOneFta(state, action) {
      state.ftas = state.ftas.map(item => {
        if (item.ftaId !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },

    removeOneFta(state, action) {
      state.ftas = state.ftas.filter(item => item.ftaId !== action.payload.id);
    }
  }
});

export const {
  getAllFta,
  getOneFta,
  createOneFta,
  updateOneFta,
  removeOneFta
} = ftaSlice.actions;
export default ftaSlice.reducer;
