import React, { createContext, ReactNode, useCallback, useState } from 'react';
import { ModalProps } from 'react-bootstrap';
import FileManager from '../components/base/FileManager';
import { TMedia } from '../types/module';

interface FileManagerContextProps {
  showMediaManager: () => Promise<TMedia>;
  media?: TMedia;
}

const FileManagerContext = createContext({} as FileManagerContextProps);

export const FileManagerProvider = ({ children }: { children: ReactNode }) => {
  const [modal, setModal] = useState<ModalProps>({ show: false });
  const [media, setMedia] = useState<TMedia | undefined>();

  const showMediaManager = useCallback(() => {
    return new Promise<TMedia>(resolve => {
      setModal({
        show: true,
        onSelectedMedia: (selectedMedia: TMedia) => {
          setMedia(selectedMedia);
          setModal({ show: false }); // Close modal after media is selected
          resolve(selectedMedia); // Resolve the promise with the selected media
        }
      });
    });
  }, []);

  return (
    <FileManagerContext.Provider value={{ showMediaManager, media }}>
      {children}
      <FileManager
        modal={modal}
        onClose={() => setModal({ show: false })}
        onSelectedMedia={modal.onSelectedMedia}
      />
    </FileManagerContext.Provider>
  );
};

export { FileManagerContext };
