import { createSlice } from '@reduxjs/toolkit';
import { TFtaTrs } from '../../../types/fta';

interface FtaTrsState {
  ftaTrss: TFtaTrs[];
  ftaTrs: TFtaTrs;
}

const initialState: FtaTrsState = {
  ftaTrss: [],
  ftaTrs: <TFtaTrs>{}
};

const ftaTrsSlice = createSlice({
  name: 'ftaTrs',
  initialState,
  reducers: {
    getAllFtaTrs(state, action) {
      state.ftaTrss = action.payload.results || [];
    },
    getOneFtaTrs(state, action) {
      state.ftaTrs = action.payload.row || null;
    },
    createOneFtaTrs(state, action) {
      state.ftaTrss = [...state.ftaTrss, action.payload.row];
    },
    updateOneFtaTrs(state, action) {
      state.ftaTrss = state.ftaTrss.map(item => {
        if (item.ftatrsId !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },

    removeOneFtaTrs(state, action) {
      state.ftaTrss = state.ftaTrss.filter(
        item => item.ftatrsId !== action.payload.id
      );
    }
  }
});

export const {
  getAllFtaTrs,
  getOneFtaTrs,
  createOneFtaTrs,
  updateOneFtaTrs,
  removeOneFtaTrs
} = ftaTrsSlice.actions;
export default ftaTrsSlice.reducer;
