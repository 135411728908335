import { createSlice } from '@reduxjs/toolkit';
import { TCompany } from '../../../types/module';

interface CompanyState {
  companies: TCompany[];
  company: TCompany;
}

const initialState: CompanyState = {
  companies: [],
  company: <TCompany>{}
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    getAllCompany(state, action) {
      state.companies = action.payload.results || [];
    },
    getOneCompany(state, action) {
      state.company = action.payload.row || null;
    },
    createOneCompany(state, action) {
      state.companies = [...state.companies, action.payload.row];
    },
    updateOneCompany(state, action) {
      state.companies = state.companies.map(item => {
        if (item.companyId !== action.payload.companyId) return item;
        else return { ...item, ...action.payload.row };
      });
    },
    removeOneCompany(state, action) {
      state.companies = state.companies.filter(
        item => item.companyId !== action.payload.id
      );
    }
  }
});

export const {
  getAllCompany,
  getOneCompany,
  createOneCompany,
  updateOneCompany,
  removeOneCompany
} = companySlice.actions;
export default companySlice.reducer;
