import { createSlice } from '@reduxjs/toolkit';
import { TMedia } from '../../../types/module';

interface MediaState {
  medias: TMedia[];
  media: TMedia;
}

const initialState: MediaState = {
  medias: [],
  media: <TMedia>{}
};

const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    getAllMedia(state, action) {
      state.medias = action.payload.results || [];
    },
    getOneMedia(state, action) {
      state.media = action.payload.row || null;
    },
    createOneMedia(state, action) {
      state.medias = [action.payload.row, ...state.medias];
    },
    updateOneMedia(state, action) {
      state.medias = state.medias.map(item => {
        if (item.mediaId !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },

    removeOneMedia(state, action) {
      state.medias = state.medias.filter(
        item => item.mediaId !== action.payload.id
      );
    }
  }
});

export const {
  getAllMedia,
  getOneMedia,
  createOneMedia,
  updateOneMedia,
  removeOneMedia
} = mediaSlice.actions;
export default mediaSlice.reducer;
