import { createSlice } from '@reduxjs/toolkit';
import { TCmsContent } from '../../../types/cms-content';

interface CmsContentState {
  cmsContents: TCmsContent[];
  cmsContent: TCmsContent;
}

const initialState: CmsContentState = {
  cmsContents: [],
  cmsContent: <TCmsContent>{}
};

const cmsContentSlice = createSlice({
  name: 'cmsContent',
  initialState,
  reducers: {
    getAllCmsContent(state, action) {
      state.cmsContents = action.payload.results || [];
    },
    getOneCmsContent(state, action) {
      state.cmsContent = action.payload.row || null;
    },
    createOneCmsContent(state, action) {
      state.cmsContents = [...state.cmsContents, action.payload.row];
      state.cmsContent = action.payload.row || null;
    },
    updateOneCmsContent(state, action) {
      state.cmsContents = state.cmsContents.map(item => {
        if (item.cmsContentId !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
      state.cmsContent = action.payload.row || null;
    },

    removeOneCmsContent(state, action) {
      state.cmsContents = state.cmsContents.filter(
        item => item.cmsContentId !== action.payload.id
      );
    }
  }
});

export const {
  getAllCmsContent,
  getOneCmsContent,
  createOneCmsContent,
  updateOneCmsContent,
  removeOneCmsContent
} = cmsContentSlice.actions;
export default cmsContentSlice.reducer;
