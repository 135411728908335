import { Dropdown, Nav } from 'react-bootstrap';
import { UilSetting, UilUserCircle } from '@iconscout/react-unicons';
import Unicon from 'components/base/Unicon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProfileDropdownMenu from './ProfileDropdownMenu';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import useAuthHook from '../../../hooks/useAuthHook';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ProfileImage from '../../../assets/img/media/img.png';

const NavItems = () => {
  const { user } = useAuthHook();
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <div className="navbar-nav navbar-nav-icons flex-row">
      <Nav.Item>
        <Link className="dropdown-caret-none nav-link h-100" to={'/settings'}>
          <Unicon icon={UilSetting} className="text-black" />
        </Link>
      </Nav.Item>
      {/*<Nav.Item>*/}
      {/*  <a href="#" className="dropdown-caret-none nav-link h-100">*/}
      {/*    <Unicon icon={UilUserCircle} className="text-black" />*/}
      {/*  </a>*/}
      {/*</Nav.Item>*/}
      <Nav.Item>
        <Dropdown
          autoClose="outside"
          show={showDropdown} // Control dropdown visibility
          onToggle={isOpen => setShowDropdown(isOpen)} // Handle toggle
          className="h-100"
        >
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link pe-0 py-0 lh-1 h-100 d-flex align-items-center"
            variant=""
          >
            {/*<Avatar className="defaultUserImage" src={''} size="l" />*/}
            <a href="#" className="dropdown-caret-none nav-link h-100">
              <Unicon icon={UilUserCircle} className="text-black" />
            </a>
            <span className="ms-2">{user?.username}</span>
            {showDropdown ? (
              <FontAwesomeIcon icon={faCaretUp} className="fs-0 ms-2" />
            ) : (
              <FontAwesomeIcon icon={faCaretDown} className="fs-0 ms-2" />
            )}
          </Dropdown.Toggle>
          <ProfileDropdownMenu
            handleToggle={() => setShowDropdown(false)}
            image={ProfileImage}
          />
        </Dropdown>
      </Nav.Item>
    </div>
  );
};

export default NavItems;
