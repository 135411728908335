import { createSlice } from '@reduxjs/toolkit';
import { TFtaType } from '../../../types/module';

interface FtaTypeState {
  ftaTypes: TFtaType[];
  ftaType: TFtaType;
}

const initialState: FtaTypeState = {
  ftaTypes: [],
  ftaType: <TFtaType>{}
};

const ftaTypeSlice = createSlice({
  name: 'ftaType',
  initialState,
  reducers: {
    getAllFtaType(state, action) {
      state.ftaTypes = action.payload.results || [];
    },
    getOneFtaType(state, action) {
      state.ftaType = action.payload.row || null;
    },
    createOneFtaType(state, action) {
      state.ftaTypes = [...state.ftaTypes, action.payload.row];
    },
    updateOneFtaType(state, action) {
      state.ftaTypes = state.ftaTypes.map(item => {
        if (item.ftaTypeId !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },

    removeOneFtaType(state, action) {
      state.ftaTypes = state.ftaTypes.filter(
        item => item.ftaTypeId !== action.payload.id
      );
    }
  }
});

export const {
  getAllFtaType,
  getOneFtaType,
  createOneFtaType,
  updateOneFtaType,
  removeOneFtaType
} = ftaTypeSlice.actions;
export default ftaTypeSlice.reducer;
