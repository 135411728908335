import api from '../utils/api';
import { TCmsContent } from '../types/cms-content';

const authEndpoint: string = `v1/cmsContent`;

const fetchAll = (languageId: number) => {
  if (languageId) {
    return api.get(`${authEndpoint}?lang=${languageId}`);
  } else {
    return api.get(`${authEndpoint}`);
  }
};
const fetchOne = (slug: string, languageId: number) => {
  return api.get(`${authEndpoint}/${slug}?lang=${languageId}`);
};

const create = (data: TCmsContent) => {
  return api.post(`${authEndpoint}`, data);
};

const update = (id: number, data: TCmsContent) => {
  return api.put(`${authEndpoint}/${id}`, data);
};
const destroy = (id: number) => {
  return api.delete(`${authEndpoint}/${id}`);
};

export default {
  fetchAll,
  fetchOne,
  create,
  update,
  destroy
};
